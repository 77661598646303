.site-header {
  #toggle {
    margin: 0;
    cursor: pointer;
    width: 100%;
    position: relative;
    z-index: 101;
    display: block !important;
    float: right;
    border-radius: 50%;
    text-align: center;
    top: 25%;
  }
  &__main {
    width: 100%;
    padding: 0px;
    .btn {
      max-width: 140px;
      min-width: 140px;
      svg {
      }
    }
  }
  &__logo{
    a {
      width:100%;
    }
  }
  &__home {
    font-size: 0;
    width: 100%;
    a {
      font-size: 25px;
      text-align: center;
      width: 100%;
      border-left: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      svg, img {
        display: block;
        position: absolute;
        //top:35%;
        top:50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      label {
        display: block;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
      }
    }
  }
  &__icons {
    font-size: 0;
    width: 100%;
    a {
      text-align: center;
      border-left: 1px solid #cccccc;
      width: 100%;
      svg {
        //margin: 0 auto 10px;
        display: block;
        //top:35%;
      }
      &:last-of-type {
        border-right: none !important;
      }
      label {
        display: block;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
      }
    }

  }
}

@media only screen and (min-width: 1101px) {
  .main-nav {
    display: none !important;
  }
}

@media only screen and (max-width: 1100px) {
  .main-nav {
    top: 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-nav ul {
    display: none !important;
  }
}
